// import('./bootstrap')
// 	.catch(err => console.error(err));

import { loadManifest } from '@angular-architects/module-federation';
import { environment } from './environments/environment';

loadManifest("/assets/mf.manifest.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err));

// Removing console in Prod
  // if(environment.production) {
  //   window.console.log = () => {}
  // }